<template>
  <v-row>
     <v-col cols="12" sm="12" md="12" class="text-left mt-4">
     <v-toolbar-title class="pl-5">Win Lose Cock Statement</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>
    <v-col cols="12" sm="12" md="4" class="text-right pl-5">
        <v-select
          v-model="currency_type"
          :items="items"
          :label="$t('input.select_currency')"
          item-text="name"
          item-value="id"
          @input="selectCurrency(currency_type)"
        ></v-select>
    </v-col>         
    <v-col cols="6" sm="6" md="2" class="text-right pl-5">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="ចាប់ផ្តើម"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
    </v-menu>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="6"
      sm="6"
      md="2"
      class="text-right pl-5 "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="បញ្ចប់"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="8" sm="8" md="2" class="text-right pl-5">
      <v-text-field
        v-model="v_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
         class="pl-5"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5 mb-5">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="getData()"
      >
        SEARCH
      </v-btn>
    </v-col>
  </v-row>
  
</template>

<script>
import {mapActions} from "vuex";
import moment from "moment";

export default {
  props: ["darkmode", "status", "params", "dataDelete", 'userPermission',"user", "time"],
  data() {
    return {
      data: {
         descendingFormat: [true],
          sortByFormat: ['_id._id'],
          descending: true,
          sortBy: null,
          page: 1,
          rowsPerPage: 10,
            fields: ["_id.group_no","_id.fight_no"],
            search: '',
            startDate:  moment().format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD"),
            module_id: null,
            currency_type: 1
          },
      startDate:  moment().format("YYYY-MM-DD"),
      endDate:  moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_search:"",
      module_id: null,
      currency_type:1,
      items: [
        {name:"USD", id:1},
        {name:"KH", id:2},
      ],
    };
  },
 watch: {
    time: function(newVal) {
      // Emit this information to the parents component
    this.startDate = newVal.startDate;
    this.endDate = newVal.endDate;
    } 
  },

 computed: {

 },
  methods: {
    closeDelete() {
      this.dialogDelete = !this.dialogDelete
    },
    async getData() {
      this.data.startDate = this.startDate;
      this.data.endDate = this.endDate;
      this.data.search = this.v_search;
      this.data.module_id = this.module_id;
      this.data.currency_type = this.currency_type;
      let preData = {
        data : this.data,
      }
      this.fetchListStatement(preData).then(() => {
            let date = {
              startDate : this.startDate,
              endDate : this.endDate,
              currency_type : this.currency_type
              
            }
           this.$emit("child-checkbox", date);
          })
    },
    selectCurrency(currency_type){
      this.data.startDate = this.startDate;
      this.data.endDate = this.endDate;
      this.data.search = this.v_search;
      this.data.module_id = this.module_id;
      this.data.currency_type = currency_type;
     
      let preData = {
        data : this.data,
      }
      this.fetchListStatement(preData).then(() => {
            let date = {
              startDate : this.startDate,
              endDate : this.endDate,
              currency_type : currency_type
            }
           this.$emit("child-checkbox", date);
          })
    },

    ...mapActions("$_winLoseLottoLiveStatement", ["fetchListStatement"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom{
        position: relative;
        // right: 250px;
        top: 10px;        
    }
    .tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
</style>
